import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import * as Moment from 'moment';
import * as Lazy from 'lazy.js';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { AGE_GROUPS } from 'Src/consts/school';
import { SchoolForm } from 'Src/models/form';
import {
  deleteSchoolUnionSchool,
  getAllSchoolUnionSchools,
  getSchoolUnionSchools,
  getSchoolUnionSchoolsCount,
  getUpcomingTournaments
} from 'Src/helpers/service/adminSU/schoolsTable';
import {
  getSchoolUnionSchoolStudents,
  getSchoolUnionSchoolForms
} from 'Src/helpers/service/adminSU/schoolStudentsTable';
import { sortAges } from 'Src/helpers/event/event';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { SCHOOL_TABS } from 'Src/consts/school';
import { SchoolSummary } from 'Src/components/SchoolSummary/SchoolSummary';
import { SchoolUpcomingTournamentsSummary } from 'Src/components/SchoolUpcomingTournamentsSummary/SchoolUpcomingTournamentsSummary';
import { UpcomingTournament } from 'Src/models/upcomingTournament';
import { SchoolAdminsSummary } from 'Src/components/SchoolAdminsSummary/SchoolAdminsSummary';
import { Button } from 'Src/components/Button/Button';
import { getAllSchoolAdmins } from 'Src/helpers/service/adminSU/schoolAdminsTable';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { SchoolFormModal } from './SchoolFormModal/SchoolFormModal';
import { addSchoolUnionSchool } from '../../../../helpers/service/adminSU/schoolsTable';
import { UnionSchoolClubsReportForm } from './Reports/UnionSchoolClubsReportForm/UnionSchoolClubsReportForm';
import { UnionSchoolFixturesReportForm } from './Reports/UnionSchoolFixturesReportForm/UnionSchoolFixturesReportForm';
import { UnionSchoolTargetCompletionReportForm } from './Reports/UnionSchoolTargetCompletionReportForm/UnionSchoolTargetCompletionReportForm';
import { UnionSchoolTargetParticipationStatisticsReportForm } from './Reports/UnionSchoolTargetParticipationStatisticsReportForm/UnionSchoolTargetParticipationStatisticsReportForm';
import { UnionSchoolTargetCompletionStatisticsReportForm } from './Reports/UnionSchoolTargetCompletionStatisticsReportForm/UnionSchoolTargetCompletionStatisticsReportForm';
import { SchoolTournamentScheduleUnionSideReportForm } from './Reports/SchoolTournamentScheduleUnionSideReportForm/SchoolTournamentScheduleUnionSideReportForm';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Postcode',
    field: 'postcode',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: ['postcode', 'postcode']
  },
  {
    text: 'Domain',
    field: 'domain',
    isSort: true,
    type: FILTER_TYPE.NONE,
    accessor: ['domain']
  }
];

interface State {
  items: any[];
  upcomingTournaments: UpcomingTournament[];
  currentPage: number;
  selectedItems: any[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  isSchoolFormOpen: boolean;
  isUnionSchoolClubsReportModalOpen: boolean;
  isUnionSchoolFixturesReportModalOpen: boolean;
  isUnionSchoolTargetCompletionReportModalOpen: boolean;
  isUnionSchoolTargetParticipationStatisticsReportModalOpen: boolean;
  isUnionSchoolTargetCompletionStatisticsReportModalOpen: boolean;
  isSchoolTournamentScheduleUnionSideReportModalOpen: boolean;
  errorMessage: string;
  isErrorModalOpen: boolean;

  currentTabIndex: number;
  admins: any[];
  students: any[];
  forms: any[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class Schools extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      upcomingTournaments: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      isSchoolFormOpen: false,
      isUnionSchoolClubsReportModalOpen: false,
      isUnionSchoolFixturesReportModalOpen: false,
      isUnionSchoolTargetCompletionReportModalOpen: false,
      isUnionSchoolTargetParticipationStatisticsReportModalOpen: false,
      isUnionSchoolTargetCompletionStatisticsReportModalOpen: false,
      isSchoolTournamentScheduleUnionSideReportModalOpen: false,
      errorMessage: '',
      isErrorModalOpen: false,

      currentTabIndex: 0,
      admins: [],
      students: [],
      forms: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolUnionSchools(user, serverQueryFilter);
    const getItemsCountPromise = getSchoolUnionSchoolsCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0 //  required to display the tab with new data for upcoming tournaments.
    }); //  I don't see any other solution yet
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllSchoolUnionSchools(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  onDeleteSchoolClick = () => {
    const { selectedItems } = this.state;
    const user = this.props.user;
    const itemIds = selectedItems.map(item => item.id);

    this.setState({
      isLoading: true
    });

    BPromise.all(itemIds.map(itemId => deleteSchoolUnionSchool(user, itemId))).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false
      });
      this.setItems();
    });
  };

  onCancelButtonClick = (): void => {
    this.setState({
      isSchoolFormOpen: false
    });
  };

  onAddSchoolButtonClick = (): void => {
    this.setState({
      isSchoolFormOpen: true
    });
  };

  onSaveButtonClick = (data): void => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    addSchoolUnionSchool(user, data).then(school => {
      this.setState({
        isLoading: false,
        isSchoolFormOpen: false
      });
      this.setItems();
    });
  };

  onManageStudentsClick = () => {
    const { user } = this.props;
    const selectedItems = this.state.selectedItems;
    const school = selectedItems[0];
    const schoolId = school.id;

    this.setState({ isLoading: true });

    getSchoolUnionSchoolStudents(user, schoolId)
      .then(students => {
        this.setState({
          currentTabIndex: 3,
          students,
          isLoading: false
        });

        this.props.history.push({
          pathname: `${this.state.basePath}/students`,
          search: `school=${schoolId}`
        });
      })
      .catch(error => {
        console.error('Failed to fetch students:', error);
        this.setState({ isLoading: false });
      });
  };

  onManageFormsClick = () => {
    const { user } = this.props;
    const selectedItems = this.state.selectedItems;
    const school = selectedItems[0];
    const schoolId = school.id;

    this.setState({ isLoading: true });

    getSchoolUnionSchoolForms(user, schoolId)
      .then(forms => {
        console.log('Fetched forms:', forms); // Debugging line
        this.setState({
          currentTabIndex: 4,
          forms,
          isLoading: false
        });

        this.props.history.push({
          pathname: `${this.state.basePath}/forms`,
          search: `school=${schoolId}`
        });
      })
      .catch(error => {
        console.error('Failed to fetch forms:', error);
        this.setState({ isLoading: false });
      });
  };

  renderSchoolForm() {
    const { isSchoolFormOpen } = this.state;
    const { user } = this.props;
    const schools = this.state.items;

    return (
      <SimpleModal isOpen={isSchoolFormOpen} title={'Select a school to add to the union'}>
        <SchoolFormModal
          user={user}
          schools={schools}
          onCancel={this.onCancelButtonClick}
          onSubmit={this.onSaveButtonClick}
        />
      </SimpleModal>
    );
  }

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const selectedItems = this.state.selectedItems;
    const school = selectedItems[0];
    const schoolId = propz.get(school, ['id'], '');

    const ageGroup = AGE_GROUPS['ENGLISH'];

    const {
      dateTo,
      dateFrom,
      ageGroups,
      genders,
      isShowInactive,
      isShowBlank,
      isGroupBySports,
      isShowCompleted,
      isShowUncompleted,
      isPrintFullNames,
      isPrintEligible,
      isPrintParticipating,
      isPrintPercent,
      isPrintPP,
      isPrintSEN,
      isPrintFSM,
      isShowBooked,
      isShowWaitingList,
      isShowRemoved,
      isShowOtherUnionTournaments
    } = data;

    let modData;
    let formsUniq;
    let ages: number[];

    REPORT_TYPE_SERVER_TO_CLIENT_MAPPING;

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_CLUBS:
        this.setState({
          isUnionSchoolClubsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        ages = ageGroups.filter(age => age.isChecked).map(age => ageGroup.indexOf(age.name));

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            ageGroups: ages,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            showInactive: isShowInactive,
            showBlank: isShowBlank
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_FIXTURES:
        this.setState({
          isUnionSchoolFixturesReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        ages = ageGroups.filter(age => age.isChecked).map(age => ageGroup.indexOf(age.name));

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            ageGroups: ages,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            showBlank: isShowBlank,
            groupBySports: isGroupBySports
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_TARGET_COMPLETION:
        this.setState({
          isUnionSchoolTargetCompletionReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        ages = ageGroups.filter(age => age.isChecked).map(age => ageGroup.indexOf(age.name));

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            ageGroups: ages,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            showCompleted: isShowCompleted,
            showUncompleted: isShowUncompleted,
            showBlank: isShowBlank,
            printFullNames: isPrintFullNames
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_TARGET_PARTICIPATION_STATISTICS:
        this.setState({
          isUnionSchoolTargetParticipationStatisticsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        ages = ageGroups.filter(age => age.isChecked).map(age => ageGroup.indexOf(age.name));

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            ageGroups: ages,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            printEligible: isPrintEligible,
            printPercent: isPrintPercent,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_SCHOOL_TARGET_COMPLETION_STATISTICS:
        this.setState({
          isUnionSchoolTargetCompletionStatisticsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        ages = ageGroups.filter(age => age.isChecked).map(age => ageGroup.indexOf(age.name));

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            ageGroups: ages,
            genders: genders.filter(gender => gender.isChecked === true).map(gender => gender.name.toUpperCase()),
            printParticipating: isPrintParticipating,
            printPercent: isPrintPercent,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.SCHOOL_TOURNAMENT_SCHEDULE_UNION_SIDE:
        this.setState({
          isSchoolTournamentScheduleUnionSideReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            schoolId,
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            showBooked: isShowBooked,
            showWaitingList: isShowWaitingList,
            showRemoved: isShowRemoved,
            showOtherUnionTournaments: isShowOtherUnionTournaments
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderUnionSchoolClubsReportFormModal() {
    const { user } = this.props;
    const { isUnionSchoolClubsReportModalOpen } = this.state;
    const { activeSchool } = user;

    return (
      <SimpleModal title="Union-wide Extracurricular Club Directory" isOpen={isUnionSchoolClubsReportModalOpen}>
        <UnionSchoolClubsReportForm
          onCloseClick={this.closeUnionSchoolClubsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderUnionSchoolFixturesReportFormModal() {
    const { user } = this.props;
    const { isUnionSchoolFixturesReportModalOpen } = this.state;
    const { activeSchool } = user;

    return (
      <SimpleModal title="Union-wide Sport Event Summary" isOpen={isUnionSchoolFixturesReportModalOpen}>
        <UnionSchoolFixturesReportForm
          onCloseClick={this.closeUnionSchoolFixturesReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderUnionSchoolTargetCompletionReportFormModal() {
    const { user } = this.props;
    const { isUnionSchoolTargetCompletionReportModalOpen } = this.state;
    const { activeSchool } = user;

    return (
      <SimpleModal title="Union-wide Target Completion Summary" isOpen={isUnionSchoolTargetCompletionReportModalOpen}>
        <UnionSchoolTargetCompletionReportForm
          onCloseClick={this.closeUnionSchoolTargetCompletionReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderUnionSchoolTargetParticipationStatisticsReportFormModal() {
    const { user } = this.props;
    const { isUnionSchoolTargetParticipationStatisticsReportModalOpen } = this.state;
    const { activeSchool } = user;

    return (
      <SimpleModal
        title="Union-wide Target Participation Statistics"
        isOpen={isUnionSchoolTargetParticipationStatisticsReportModalOpen}
      >
        <UnionSchoolTargetParticipationStatisticsReportForm
          onCloseClick={this.closeUnionSchoolTargetParticipationStatisticsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderUnionSchoolTargetCompletionStatisticsReportFormModal() {
    const { user } = this.props;
    const { isUnionSchoolTargetCompletionStatisticsReportModalOpen } = this.state;
    const { activeSchool } = user;

    return (
      <SimpleModal
        title="Union-wide Target Completion Statistics"
        isOpen={isUnionSchoolTargetCompletionStatisticsReportModalOpen}
      >
        <UnionSchoolTargetCompletionStatisticsReportForm
          onCloseClick={this.closeUnionSchoolTargetCompletionStatisticsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderSchoolTournamentScheduleUnionSideReportFormModal() {
    const { user } = this.props;
    const { isSchoolTournamentScheduleUnionSideReportModalOpen, selectedItems } = this.state;
    const { activeSchool } = user;
    const school = selectedItems[0];

    return (
      <SimpleModal title="School tournament schedule" isOpen={isSchoolTournamentScheduleUnionSideReportModalOpen}>
        <SchoolTournamentScheduleUnionSideReportForm
          onCloseClick={this.closeSchoolTournamentScheduleUnionSideReportForm}
          onSubmit={this.onSubmitReportForm}
          school={school}
        />
      </SimpleModal>
    );
  }

  openUnionSchoolClubsReportForm = () => {
    this.setState({
      isUnionSchoolClubsReportModalOpen: true
    });
  };

  closeUnionSchoolClubsReportForm = () => {
    this.setState({
      isUnionSchoolClubsReportModalOpen: false
    });
  };

  openUnionSchoolFixturesReportForm = () => {
    this.setState({
      isUnionSchoolFixturesReportModalOpen: true
    });
  };

  closeUnionSchoolFixturesReportForm = () => {
    this.setState({
      isUnionSchoolFixturesReportModalOpen: false
    });
  };

  openUnionSchoolTargetCompletionReportForm = () => {
    this.setState({
      isUnionSchoolTargetCompletionReportModalOpen: true
    });
  };

  closeUnionSchoolTargetCompletionReportForm = () => {
    this.setState({
      isUnionSchoolTargetCompletionReportModalOpen: false
    });
  };

  openUnionSchoolTargetParticipationStatisticsReportForm = () => {
    this.setState({
      isUnionSchoolTargetParticipationStatisticsReportModalOpen: true
    });
  };

  closeUnionSchoolTargetParticipationStatisticsReportForm = () => {
    this.setState({
      isUnionSchoolTargetParticipationStatisticsReportModalOpen: false
    });
  };

  openUnionSchoolTargetCompletionStatisticsReportForm = () => {
    this.setState({
      isUnionSchoolTargetCompletionStatisticsReportModalOpen: true
    });
  };

  closeUnionSchoolTargetCompletionStatisticsReportForm = () => {
    this.setState({
      isUnionSchoolTargetCompletionStatisticsReportModalOpen: false
    });
  };

  openSchoolTournamentScheduleUnionSideReportForm = () => {
    this.setState({
      isSchoolTournamentScheduleUnionSideReportModalOpen: true
    });
  };

  closeSchoolTournamentScheduleUnionSideReportForm = () => {
    this.setState({
      isSchoolTournamentScheduleUnionSideReportModalOpen: false
    });
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const actionItems = [
      selectedItems.length === 0 && {
        itemText: 'Add school',
        onItemClick: this.onAddSchoolButtonClick,
        isActive: true
      },
      selectedItems.length >= 1 && {
        itemText: selectedItems.length === 1 ? 'Delete school' : 'Delete schools',
        onItemClick: this.onDeleteSchoolClick,
        isActive: true
      },
      (selectedItems.length === 1 || selectedItems.length === 0) && {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          selectedItems.length == 0 && {
            itemText: 'Union-wide Extracurricular Club Directory',
            onItemClick: this.openUnionSchoolClubsReportForm,
            isActive: true
          },
          selectedItems.length === 0 && {
            itemText: 'Union-wide Sport Event Summary',
            onItemClick: this.openUnionSchoolFixturesReportForm,
            isActive: true
          },
          selectedItems.length === 0 && {
            itemText: 'Union-wide Aggregated Target Completion Summary',
            onItemClick: this.openUnionSchoolTargetCompletionReportForm,
            isActive: true
          },
          selectedItems.length === 0 && {
            itemText: 'Union-wide Target Participation Statistics',
            onItemClick: this.openUnionSchoolTargetParticipationStatisticsReportForm,
            isActive: true
          },
          selectedItems.length === 0 && {
            itemText: 'Union-wide Target Completion Statistics',
            onItemClick: this.openUnionSchoolTargetCompletionStatisticsReportForm,
            isActive: true
          },
          selectedItems.length === 1 && {
            itemText: 'School Tournament Schedule',
            onItemClick: this.openSchoolTournamentScheduleUnionSideReportForm,
            isActive: true
          }
        ].filter(Boolean)
      }
    ].filter(Boolean);

    const filterOptions = {};

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  getSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems, upcomingTournaments, admins } = this.state;

    const school = selectedItems[0];
    switch (currentTabIndex) {
      case 0:
        return <SchoolSummary school={school} />;
      case 1:
        return <SchoolUpcomingTournamentsSummary upcomingTournaments={upcomingTournaments} />;
      case 2:
        return <SchoolAdminsSummary admins={admins} />;
      case 3:
        return this.renderStudentSummary();
      case 4:
        return this.renderFormSummary();
      default:
        return null;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const { selectedItems } = this.state;
    const school = selectedItems[0];
    const memberSchoolId = school.id;

    switch (index) {
      case 1:
        this.setState({
          isLoading: true
        });
        getUpcomingTournaments(user, memberSchoolId).then(upcomingTournaments => {
          this.setState({
            currentTabIndex: index,
            upcomingTournaments: upcomingTournaments,
            isLoading: false
          });
        });
        break;
      case 2:
        this.setState({
          isLoading: true
        });
        getAllSchoolAdmins(user, memberSchoolId).then(admins => {
          this.setState({
            currentTabIndex: index,
            admins: admins,
            isLoading: false
          });
        });
      case 3:
        this.setState({
          isLoading: true
        });
        getSchoolUnionSchoolStudents(user, memberSchoolId).then(students => {
          this.setState({
            currentTabIndex: index,
            students: students,
            isLoading: false
          });
        });
        break;
      case 4:
        this.setState({
          isLoading: true
        });
        getSchoolUnionSchoolForms(user, memberSchoolId).then(forms => {
          this.setState({
            currentTabIndex: index,
            forms: forms,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  onShowAdminsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const school = selectedItems[0];
    const schoolId = school.id;

    this.props.history.push({
      pathname: `${this.state.basePath}/admins`,
      search: `school=${schoolId}`
    });
  };

  renderSummary(): React.ReactNode {
    const { currentTabIndex } = this.state;

    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a school above</div>;
    }

    const summaryTabs = this.getSummaryTabs();

    const isShowAdminsButton = currentTabIndex === 2;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={SCHOOL_TABS} currentTabIndex={currentTabIndex}>
        {isShowAdminsButton && (
          <Button onClick={this.onShowAdminsButtonClick} text={'Show admins'} customClass={'mt-3 mb-3 mr-3'} />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  renderStudentSummary(): React.ReactNode {
    return (
      <div>
        <Button onClick={this.onManageStudentsClick} text={'Manage Students'} customClass={'mt-3 mb-3'} />
      </div>
    );
  }

  renderFormSummary(): React.ReactNode {
    const { forms } = this.state;

    if (!forms || forms.length === 0) {
      return <div>No forms available</div>;
    }

    return (
      <div>
        <Button onClick={this.onManageFormsClick} text={'Manage Forms'} customClass={'mt-3 mb-3'} />
        <ul>
          {forms.map((form, index) => (
            <li key={form.id}>
              {form.name} (Age: {form.age}, Status: {form.status})
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderSchoolForm()}
        {this.renderUnionSchoolClubsReportFormModal()}
        {this.renderUnionSchoolFixturesReportFormModal()}
        {this.renderUnionSchoolTargetCompletionReportFormModal()}
        {this.renderUnionSchoolTargetParticipationStatisticsReportFormModal()}
        {this.renderUnionSchoolTargetCompletionStatisticsReportFormModal()}
        {this.renderSchoolTournamentScheduleUnionSideReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
