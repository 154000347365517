import * as React from 'react';
import * as propz from 'propz';
import './NotificationSettings.scss';
import { History, Location } from 'history';
import { getNotificationSettings, updateNotificationSettings } from 'Src/helpers/service/admin/notificationSettings';
import { AppUser } from 'Src/views/App/App';
import { Loader } from 'Src/components/Loader/Loader';
import { Button } from 'Src/components/Button/Button';
import { Switch } from 'Src/components/Switch/Switch';
import { NOTIFICATION_STATUS_TYPE, NOTIFICATION_TYPE } from 'Src/consts/notifications';
import { Form, Formik } from 'formik';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  notificationSettings: {
    type: string;
    status: 'AUTO' | 'DISABLED';
  }[];
  isEditMode: boolean;
}

const CURRENT_NOTIFICATION_TYPE_ARRAY = [
  NOTIFICATION_TYPE.PERMISSION_REQUEST_ACCEPTED,
  NOTIFICATION_TYPE.PERMISSION_REQUEST_REJECTED,
  NOTIFICATION_TYPE.PERMISSION_CREATED,
  NOTIFICATION_TYPE.PERMISSION_REQUEST_CREATED,
  NOTIFICATION_TYPE.MERGE_STUDENT,
  NOTIFICATION_TYPE.MERGE_PARENT,
  NOTIFICATION_TYPE.EVENT_CREATED,
  NOTIFICATION_TYPE.EVENT_UPDATED,
  NOTIFICATION_TYPE.EVENT_CANCELED,
  NOTIFICATION_TYPE.EVENT_DETAILS_UPDATED,
  NOTIFICATION_TYPE.EVENT_TEAM_CHANGED,
  NOTIFICATION_TYPE.MESSAGE_INBOX_CONSENT_REQUEST,
  NOTIFICATION_TYPE.EVENT_ELIGIBLE_STUDENTS,
  NOTIFICATION_TYPE.EVENT_CUSTOM,
  NOTIFICATION_TYPE.CLUB_CREATED,
  NOTIFICATION_TYPE.CLUB_PARTICIPATION_INVITE,
  NOTIFICATION_TYPE.CLUB_ACTIVATED,
  NOTIFICATION_TYPE.CLUB_ACTIVATED_WAITING_LIST_NOTIFICATION,
  NOTIFICATION_TYPE.INVITE_INCOMING,
  NOTIFICATION_TYPE.INVITE_ACCEPTED_BY_OPPONENT,
  NOTIFICATION_TYPE.INVITE_REJECTED_BY_OPPONENT,
  NOTIFICATION_TYPE.ABSENCE_REQUEST_SENT,
  NOTIFICATION_TYPE.ABSENCE_REQUEST_UPDATED,
  NOTIFICATION_TYPE.CUSTOM
];

const SETTING_DESTINATIONS_TYPE = {
  REGISTRATION_AND_PROFILE_MANAGEMENT: 'REGISTRATION_AND_PROFILE_MANAGEMENT',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  EVENT_MANAGEMENT: 'EVENT_MANAGEMENT',
  CLUB_MANAGEMENT: 'CLUB_MANAGEMENT',
  INVITES_MANAGEMENT: 'INVITES_MANAGEMENT',
  ABSENCE_REQUESTS: 'ABSENCE_REQUESTS'
};

const SETTINGS_DESIGNATIONS = [
  { type: SETTING_DESTINATIONS_TYPE.REGISTRATION_AND_PROFILE_MANAGEMENT, name: 'Registration and profile management' },
  { type: SETTING_DESTINATIONS_TYPE.USER_MANAGEMENT, name: 'User management' },
  { type: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT, name: 'Event management' },
  { type: SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT, name: 'Club management' },
  { type: SETTING_DESTINATIONS_TYPE.INVITES_MANAGEMENT, name: 'Invites management' },
  { type: SETTING_DESTINATIONS_TYPE.ABSENCE_REQUESTS, name: 'Absence requests' }
];

const SETTINGS = [
  // Registration and profile management
  {
    type: NOTIFICATION_TYPE.PERMISSION_REQUEST_ACCEPTED,
    relation: SETTING_DESTINATIONS_TYPE.REGISTRATION_AND_PROFILE_MANAGEMENT,
    name: 'User’s role request has been accepted',
    hintText:
      'Sent to: All users\n' +
      'Sent once a user has their role request accepted by the school to let them know they can now access your school ' +
      'account based on their role permissions.'
  },
  {
    type: NOTIFICATION_TYPE.PERMISSION_REQUEST_REJECTED,
    relation: SETTING_DESTINATIONS_TYPE.REGISTRATION_AND_PROFILE_MANAGEMENT,
    name: 'User’s role request has been denied',
    hintText:
      'Sent to: All users\n' +
      'Sent once a user has their role request declined  by the school to let them know that they have not been accepted onto your school acount.'
  },
  {
    type: NOTIFICATION_TYPE.PERMISSION_CREATED,
    relation: SETTING_DESTINATIONS_TYPE.REGISTRATION_AND_PROFILE_MANAGEMENT,
    name: 'User has been granted a new role',
    hintText: 'Sent to: All users\n' + 'Sent once a user has been assigned an additional role on your school account.'
  },
  // User management
  {
    type: NOTIFICATION_TYPE.PERMISSION_REQUEST_CREATED,
    relation: SETTING_DESTINATIONS_TYPE.USER_MANAGEMENT,
    name: 'New role request',
    hintText:
      'Sent to: Admin and Manager users\n' +
      'Sent when a new role request comes through to your school. We recommend that you disable this notification when you ' +
      'first ask parents and/or students to sign up, otherwise you will recieve a high volume of notifications.'
  },
  {
    type: NOTIFICATION_TYPE.MERGE_STUDENT,
    relation: SETTING_DESTINATIONS_TYPE.USER_MANAGEMENT,
    name: 'Student merged',
    hintText:
      'Sent to: All users\n' +
      'Sent once a student user has their role request accepted by the school to let them know they have been linked to the ' +
      'relevant inactive student account.'
  },
  {
    type: NOTIFICATION_TYPE.MERGE_PARENT,
    relation: SETTING_DESTINATIONS_TYPE.USER_MANAGEMENT,
    name: 'Parent merged',
    hintText:
      'Sent to: All users\n' +
      'Sent once a parent user has their role request accepted by the school to let them know they have been linked to the ' +
      'relevant inactive parent account.'
  },
  // Event management
  {
    type: NOTIFICATION_TYPE.EVENT_CREATED,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Event has been created',
    hintText:
      'Sent to: Related teamsheet users\n' +
      'A new event has been created and anyone who is in the teamsheet or has a student they are linked to involved within the ' +
      'fixture will recieve a notification.'
  },
  {
    type: NOTIFICATION_TYPE.EVENT_UPDATED,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Event has been rescheduled',
    hintText:
      'Sent to: Related teamsheet users\n' +
      'If you reschedule the event anyone who is in the teamsheet, has a student they are linked to involved within the fixture or staff ' +
      'member that has been added to the fixture, will recieve a notification.'
  },
  {
    type: NOTIFICATION_TYPE.EVENT_CANCELED,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Event has been cancelled',
    hintText:
      'Sent to: Related teamsheet users\n' +
      'If you cancel the event anyone who is in the teamsheet, has a student they are linked to involved within the fixture or staff member' +
      ' that has been added to the fixture, will recieve a notification.'
  },
  {
    type: NOTIFICATION_TYPE.EVENT_DETAILS_UPDATED,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Event details have been updated',
    hintText:
      'Sent to: Related teamsheet users\n' +
      'If you amend any event details: time / location / age group anyone who is in the teamsheet, has a student they are linked to involved within' +
      ' the fixture or staff member that has been added to the fixture, will recieve a notification.'
  },
  {
    type: NOTIFICATION_TYPE.EVENT_TEAM_CHANGED,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Participants have been updated',
    hintText:
      'Sent to: Related teamsheet users\n' +
      'If you add or remove any students from the teamsheet then the users related to that student will get a notification:\n' +
      'John Smith was added to the event - any parents linked to John and John will get notified;\n' +
      'John Smile was removed from the event - any parents linked to John and John will get notified.'
  },
  {
    type: NOTIFICATION_TYPE.MESSAGE_INBOX_CONSENT_REQUEST,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Consent request has been sent',
    hintText:
      'Sent to: Parental users\n' +
      'This will go out to all parental accounts linked to students within the teamsheet to let them know they have a new parental consent request.'
  },
  {
    type: NOTIFICATION_TYPE.EVENT_ELIGIBLE_STUDENTS,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'A challenge is available for joining',
    hintText:
      'Sent to: Student users\n' +
      'When you set up a challenge that students can join onto themselves, all eligible students will recieve a notification.'
  },
  {
    type: NOTIFICATION_TYPE.EVENT_CUSTOM,
    relation: SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT,
    name: 'Custom event notification',
    hintText:
      'Sent to: Selected users\n' +
      'This will enable you to compose your own notification and select users who you would like to recieve it, please note you ' +
      'can only send to users who are related to the event teamsheet.'
  },
  // Club management
  {
    type: NOTIFICATION_TYPE.CLUB_CREATED,
    relation: SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT,
    name: 'Club has been created',
    hintText:
      'Sent to: Related staff users\n' +
      'This will be sent when you have created a new club, but not activated it yet. It will only go to staff members you have linked to the club.'
  },
  {
    type: NOTIFICATION_TYPE.CLUB_ACTIVATED,
    relation: SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT,
    name: 'Club has been activated',
    hintText:
      'Sent to: Related parental and student users\n' +
      'Once you activate a club a notification will go out to any students and parents of the students who are in the register.'
  },
  {
    type: NOTIFICATION_TYPE.CLUB_PARTICIPATION_INVITE,
    relation: SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT,
    name: 'Club invitation has been received',
    hintText:
      'Sent to: Parental / Student users\n' +
      'When setting up your club you can decide if you would like the club invite booking form to be sent to parental users or students. ' +
      'Once you have sent club booking forms a notification will be sent to relevant users to let them know they can book onto a club.'
  },
  {
    type: NOTIFICATION_TYPE.CLUB_ACTIVATED_WAITING_LIST_NOTIFICATION,
    relation: SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT,
    name: 'Student has been added to the waiting list',
    hintText:
      'Sent to: Parental / Student users\n' +
      'This will be sent to the user type you set up the invitations to be sent to once a club has been activated. If there is not enough ' +
      'space within the club they will be notified that they / their child has been added to the club waiting list.'
  },
  // Invites management
  {
    type: NOTIFICATION_TYPE.INVITE_INCOMING,
    relation: SETTING_DESTINATIONS_TYPE.INVITES_MANAGEMENT,
    name: 'New incoming event invitation',
    hintText:
      'Sent to: Admin and Manager users\n' +
      'When you have a new event invitation from either a opposition school or your union/association you will be notified to enable you to manage the invitation.'
  },
  {
    type: NOTIFICATION_TYPE.INVITE_ACCEPTED_BY_OPPONENT,
    relation: SETTING_DESTINATIONS_TYPE.INVITES_MANAGEMENT,
    name: 'An opponent school has accepted your event invite',
    hintText:
      'Sent to: Admin and Manager users\n' +
      'If you sent an event inviation to an opposition school you will be notified if / when they have accepted.'
  },
  {
    type: NOTIFICATION_TYPE.INVITE_REJECTED_BY_OPPONENT,
    relation: SETTING_DESTINATIONS_TYPE.INVITES_MANAGEMENT,
    name: 'An opponent school has rejected your event invite',
    hintText:
      'Sent to: Admin and Manager users\n' +
      'If you sent an event inviation to an opposition school you will be notified if / when they have rejected.'
  },
  // Absence requests
  {
    type: NOTIFICATION_TYPE.ABSENCE_REQUEST_SENT,
    relation: SETTING_DESTINATIONS_TYPE.ABSENCE_REQUESTS,
    name: 'Absence report has been received',
    hintText: 'Sent to: Admin and Manager users\n' + 'Sent when a absence report comes through to your school. '
  },
  {
    type: NOTIFICATION_TYPE.ABSENCE_REQUEST_UPDATED,
    relation: SETTING_DESTINATIONS_TYPE.ABSENCE_REQUESTS,
    name: 'Absence report has been updated',
    hintText:
      'Sent to: Parental users\n' +
      'This will go out to all parental accounts once their absence report status has been updated by  school staff.'
  }
];

export class NotificationSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      notificationSettings: [],
      isEditMode: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.getNotificationSettingsFromNotificationSettingsObject();
  }

  getNotificationSettingsFromNotificationSettingsObject = (): void => {
    const user = this.props.user;

    getNotificationSettings(user).then(notificationSettingsObject => {
      const notificationSettings = this.getArrayFromObjectDesiredValues(notificationSettingsObject);
      this.setState({
        notificationSettings: notificationSettings,
        isLoading: false
      });
    });
  };

  getArrayFromObjectDesiredValues = (object: { key: string }): { type: string; status: 'AUTO' | 'DISABLED' }[] => {
    const arrayWithData = [];
    const copyObject = { ...object };

    for (let key in copyObject) {
      const existType = CURRENT_NOTIFICATION_TYPE_ARRAY.find(type => type === key);
      if (typeof existType !== 'undefined') {
        arrayWithData.push({
          type: key,
          status: copyObject[key]
        });
      }
    }
    return arrayWithData;
  };

  getNotificationSettingsObjectFromNotificationSettings = (
    arrayWithData: { type: string; status: 'AUTO' | 'DISABLED' }[]
  ): { key: string } | {} => {
    const objectDate = {};
    arrayWithData.forEach(date => {
      objectDate[date.type] = date.status;
    });
    return objectDate;
  };

  getNotificationStatus = (status: boolean): string => {
    return status ? NOTIFICATION_STATUS_TYPE.AUTO : NOTIFICATION_STATUS_TYPE.DISABLED;
  };

  getReverseStatus = (status: string): string => {
    return status === NOTIFICATION_STATUS_TYPE.AUTO ? NOTIFICATION_STATUS_TYPE.DISABLED : NOTIFICATION_STATUS_TYPE.AUTO;
  };

  getIsStatusAuto = (status: string): boolean => {
    return status === NOTIFICATION_STATUS_TYPE.AUTO;
  };

  onCloseClick = (): void => {
    this.setState({
      isEditMode: false,
      isLoading: true
    });
    this.getNotificationSettingsFromNotificationSettingsObject();
  };

  onFormSubmit = (values): void => {
    const { user } = this.props;
    const { isEditMode } = this.state;

    if (isEditMode) {
      this.setState({
        isLoading: true
      });

      const notificationSettings = this.getNotificationSettingsObjectFromNotificationSettings(values.allSettings);
      const isEventTeamChanged = propz.get(notificationSettings, ['EVENT_TEAM_CHANGED'], false);

      const data = {
        ...notificationSettings,
        EVENT_INDIVIDUAL_PLAYER_ADDED: isEventTeamChanged,
        EVENT_INDIVIDUAL_PLAYER_REMOVED: isEventTeamChanged,
        EVENT_TEAM_PLAYER_ADDED: isEventTeamChanged,
        EVENT_TEAM_PLAYER_REMOVED: isEventTeamChanged,
        EVENT_TEAM_PLAYER_UPDATED: isEventTeamChanged
      };

      updateNotificationSettings(user, data).then(objectNotificationSettings => {
        const arrayNotificationSettings = this.getArrayFromObjectDesiredValues(objectNotificationSettings);
        this.setState({
          notificationSettings: arrayNotificationSettings,
          isEditMode: false,
          isLoading: false
        });
      });
    } else {
      this.setState({
        isEditMode: true
      });
    }
  };

  onAllNotificationsChange = (setFieldValue, values): void => {
    const { isAllNotifications } = values;
    const changedAllSettings = values.allSettings.map(setting => {
      const { status, ...rest } = setting;
      return {
        ...rest,
        status: this.getNotificationStatus(!isAllNotifications)
      };
    });
    setFieldValue('isAllNotifications', !isAllNotifications);
    setFieldValue('isRegistrationAndProfileManagement', !isAllNotifications);
    setFieldValue('isUserManagement', !isAllNotifications);
    setFieldValue('isEventManagement', !isAllNotifications);
    setFieldValue('isClubManagement', !isAllNotifications);
    setFieldValue('isInvitesManagement', !isAllNotifications);
    setFieldValue('isAbsenceRequests', !isAllNotifications);
    setFieldValue('allSettings', changedAllSettings);
  };

  onGroupSettingsChange = (
    values,
    setFieldValue,
    checkType: string,
    groupSetting: { type: string; name: string }
  ): void => {
    const copyAllSettings = [...values.allSettings];
    const settings = SETTINGS.filter(setting => setting.relation === groupSetting.type);
    const checkedSettings = [...copyAllSettings].filter(adjustment => {
      return settings.some(setting => setting.type === adjustment.type);
    });

    const checkedCopySettings = copyAllSettings.map(copySetting => {
      const isExistSetting = checkedSettings.some(checkedSetting => checkedSetting.type === copySetting.type);
      if (isExistSetting) {
        const checkedSetting = { ...copySetting };
        checkedSetting.status = this.getNotificationStatus(!values[checkType]);
        return checkedSetting;
      } else {
        return copySetting;
      }
    });
    const isAutoAllSettings = this.getAllNotificationsStatus(checkedCopySettings);
    setFieldValue('isAllNotifications', isAutoAllSettings);
    setFieldValue('allSettings', checkedCopySettings);
    setFieldValue(checkType, !values[checkType]);
  };

  getCheckType = (type: string): string => {
    switch (type) {
      case SETTING_DESTINATIONS_TYPE.REGISTRATION_AND_PROFILE_MANAGEMENT:
        return 'isRegistrationAndProfileManagement';
      case SETTING_DESTINATIONS_TYPE.USER_MANAGEMENT:
        return 'isUserManagement';
      case SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT:
        return 'isEventManagement';
      case SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT:
        return 'isClubManagement';
      case SETTING_DESTINATIONS_TYPE.INVITES_MANAGEMENT:
        return 'isInvitesManagement';
      case SETTING_DESTINATIONS_TYPE.ABSENCE_REQUESTS:
        return 'isAbsenceRequests';
    }
  };

  getAllNotificationsStatus = (notificationSettings: { type: string; status: 'AUTO' | 'DISABLED' }[]): boolean => {
    const isAutoAllSettings = [...notificationSettings].every(
      setting => setting.status === NOTIFICATION_STATUS_TYPE.AUTO
    );
    return isAutoAllSettings;
  };

  getGroupSettingStatus = (
    notificationSettings: { type: string; status: 'AUTO' | 'DISABLED' }[],
    type: string
  ): boolean => {
    const copyAllSettings = [...notificationSettings];
    const settings = [...SETTINGS].filter(setting => setting.relation === type);
    const someSettings = [...copyAllSettings].filter(adjustment => {
      return settings.some(setting => setting.type === adjustment.type);
    });
    const isAutoCurrentSettings = someSettings.every(setting => setting.status === NOTIFICATION_STATUS_TYPE.AUTO);

    return isAutoCurrentSettings;
  };

  renderGroupSettingsRow = (setting: { type: string; name: string }, values, setFieldValue): React.ReactNode => {
    const { isEditMode } = this.state;
    const checkType = this.getCheckType(setting.type);
    const isChecked = values[checkType];
    return (
      <div>
        <div className="eNotificationSettingRow">
          <p className="h5 ml-2 mt-2 mb-2" style={{ width: '60%' }}>
            <strong>{setting.name}</strong>
          </p>
          <div className="ml-3" style={{ width: '15%' }}>
            <img
              style={{ width: '20px' }}
              src="/dist/images/questionMark.png"
              title={
                'This will turn on / off all notification types within this section. Notifications are sent via email as well as ' +
                'push notification as long as the users have the Squad in Touch app and push notifications are turned on in their settings.'
              }
              alt={'question icon'}
            />
          </div>
          <div className="d-flex align-content-center" style={{ width: '25%' }}>
            <p className="h5 mb-0 mr-4">
              <strong>Off</strong>
            </p>
            <Switch
              key={checkType}
              value={isChecked}
              onChange={() => this.onGroupSettingsChange(values, setFieldValue, checkType, setting)}
              text={''}
              name={checkType}
              disabled={!isEditMode}
            />
            <p className="h5 mb-0 ml-3">
              <strong>On</strong>
            </p>
          </div>
        </div>
        {this.renderSettingsRow(setting, values, setFieldValue)}
      </div>
    );
  };

  renderSettingsRow = (designationSetting: { type: string; name: string }, values, setFieldValue): React.ReactNode => {
    const { isEditMode } = this.state;
    const settings = [...SETTINGS];

    const necessarySettings = values.allSettings.filter(necessarySetting => {
      return settings.some(list => list.type === necessarySetting.type && list.relation === designationSetting.type);
    });
    return necessarySettings.map(necessarySetting => {
      const listMenu = settings.find(row => necessarySetting.type === row.type);
      const settingName = typeof listMenu !== 'undefined' ? listMenu.name : '';
      const hintText = typeof listMenu !== 'undefined' ? listMenu.hintText : '';

      return (
        <div key={`setting_type_${necessarySetting.type}`} className="eNotificationSettingRow">
          <p className="h5 ml-2 mt-2 mb-2" style={{ width: '60%' }}>
            {settingName}
          </p>
          <div className="ml-3" style={{ width: '15%' }}>
            <img style={{ width: '20px' }} src="/dist/images/questionMark.png" title={hintText} alt={'question icon'} />
          </div>
          <div className="d-flex align-content-center" style={{ width: '25%' }}>
            <p className="h5 mb-0 mr-4">Off</p>
            <Switch
              key={necessarySetting.type}
              value={this.getIsStatusAuto(necessarySetting.status)}
              onChange={() => {
                const allSettings = [...values.allSettings];
                const currentSettingIndex = allSettings.findIndex(setting => setting.type === necessarySetting.type);
                const isExistSetting = currentSettingIndex !== -1;
                if (isExistSetting) {
                  const currentSetting = { ...necessarySetting };
                  currentSetting.status = this.getReverseStatus(necessarySetting.status);
                  allSettings.splice(currentSettingIndex, 1, currentSetting);
                  setFieldValue('allSettings', allSettings);

                  const isAutoAllSettings = this.getAllNotificationsStatus(allSettings);
                  setFieldValue('isAllNotifications', isAutoAllSettings);

                  const currentSettingRelation = [...SETTINGS].find(setting => setting.type === currentSetting.type);

                  if (typeof currentSettingRelation !== 'undefined') {
                    const isCheckedGroupSettings = this.getGroupSettingStatus(
                      allSettings,
                      currentSettingRelation.relation
                    );

                    const checkType = this.getCheckType(currentSettingRelation.relation);
                    setFieldValue(checkType, isCheckedGroupSettings);
                  }
                }
              }}
              text={''}
              name={necessarySetting.type}
              disabled={!isEditMode}
            />
            <p className="h5 mb-0 ml-3">On</p>
          </div>
        </div>
      );
    });
  };

  renderSettingsList = (values, setFieldValue): React.ReactNode => {
    const settingsDesignations = [...SETTINGS_DESIGNATIONS];
    return settingsDesignations.map((setting, index) => {
      const isShowLastEmptyRow = settingsDesignations.length - 1 !== index;
      return (
        <div key={`${setting.type}`}>
          {this.renderGroupSettingsRow(setting, values, setFieldValue)}
          {isShowLastEmptyRow && <div className="eNotificationSettingEmptyRow" />}
        </div>
      );
    });
  };

  render() {
    const { notificationSettings, isLoading, isEditMode } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const buttonText = isEditMode ? 'Save' : 'Edit';

    const notificationSettingsForm = {
      allSettings: notificationSettings,
      isRegistrationAndProfileManagement: this.getGroupSettingStatus(
        notificationSettings,
        SETTING_DESTINATIONS_TYPE.REGISTRATION_AND_PROFILE_MANAGEMENT
      ),
      isUserManagement: this.getGroupSettingStatus(notificationSettings, SETTING_DESTINATIONS_TYPE.USER_MANAGEMENT),
      isEventManagement: this.getGroupSettingStatus(notificationSettings, SETTING_DESTINATIONS_TYPE.EVENT_MANAGEMENT),
      isClubManagement: this.getGroupSettingStatus(notificationSettings, SETTING_DESTINATIONS_TYPE.CLUB_MANAGEMENT),
      isInvitesManagement: this.getGroupSettingStatus(
        notificationSettings,
        SETTING_DESTINATIONS_TYPE.INVITES_MANAGEMENT
      ),
      isAbsenceRequests: this.getGroupSettingStatus(notificationSettings, SETTING_DESTINATIONS_TYPE.ABSENCE_REQUESTS),
      isAllNotifications: this.getAllNotificationsStatus(notificationSettings)
    };
    return (
      <Formik initialValues={notificationSettingsForm} onSubmit={this.onFormSubmit}>
        {({ setFieldValue, values }) => (
          <Form>
            <div className={'bGenericView'}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 eNotificationSettings">
                    <div className={''}>
                      <Button
                        text={'Cancel'}
                        disabled={!isEditMode}
                        onClick={() => {
                          this.onCloseClick();
                        }}
                        customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                      />
                      <button type="submit" className="btn btn-primary mt-3 mb-3">
                        {buttonText}
                      </button>
                    </div>
                    <div className="">
                      <div className="eNotificationSettingHead">
                        <p className="h5 ml-3 mt-2 mb-2" style={{ width: '80%' }}>
                          <strong>Notification</strong>
                        </p>
                        <p className="h5 d-flex" style={{ width: '20%' }}>
                          <strong>Status</strong>
                        </p>
                      </div>
                      <div className="eNotificationSettingRow">
                        <p className="h3 ml-2 mt-2 mb-2" style={{ width: '75%' }}>
                          <strong>All notifications</strong>
                        </p>
                        <div className="ml-3 d-flex align-content-center" style={{ width: '25%' }}>
                          <p className="h5 mb-0 mr-4">
                            <strong>Off</strong>
                          </p>
                          <Switch
                            key={'isAllNotifications'}
                            value={values.isAllNotifications}
                            onChange={() => this.onAllNotificationsChange(setFieldValue, values)}
                            text={''}
                            name="isAllNotifications"
                            disabled={!isEditMode}
                          />
                          <p className="h5 mb-0 ml-3">
                            <strong>On</strong>
                          </p>
                        </div>
                      </div>
                      {this.renderSettingsList(values, setFieldValue)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
